export const LINKS = [
  {
    label: 'bed sheets',
    link: '/products/bed-sheets',
  },
  {
    label: 'quilt covers',
    link: '/products/quilt-covers',
  },
  {
    label: 'pillowcases',
    link: '/products/pillowcases',
  },
  {
    label: 'blankets',
    link: '/products/blankets',
  },
  {
    label: 'collections',
    link: '/collections',
  },
]
