import { Breadcrumbs, Error404Page, Layout } from '@components/common'
import getGlobalStaticProps from '@lib/globalStaticProps'
import { prismicPreviewData } from '@lib/prismic'
import { Crumb } from '@lib/types/Breadcrumbs'
import type { GetStaticPropsContext } from 'next'

export async function getStaticProps({
  preview,
  previewData = {},
  locale,
}: GetStaticPropsContext | prismicPreviewData) {
  const { ref } = previewData
  const globalProps = await getGlobalStaticProps(ref, locale)

  return {
    props: {
      ...globalProps.props,
      preview: preview || false,
    },
  }
}

interface Error404 {}

export default function Error404() {
  const crumbs: Crumb[] = [
    { label: 'Home', link: '/' },
    { label: 'Page not found', link: '/404' },
  ]

  return (
    <>
      <div className="block py-16 wrapper">
        <Breadcrumbs crumbs={crumbs} />
      </div>
      <Error404Page />
    </>
  )
}

Error404.Layout = Layout
