import TagFilters from '@components/common/TagFilters'
import { Button } from '@components/ui'
import { LINKS } from '@lib/404Link'
import cn from 'classnames'
import Image from "next/legacy/image"
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

const Error404: FC = () => {
  const { locale } = useRouter()
  return <>
    <div className={cn('mx-auto w-full banner relative flex justify-center items-center overflow-hidden mb-32')}>
      <div className="hidden laptop:block">
        <Image src={`/${locale}/images/404-desktop.jpg`} alt="Sheet Society" layout="fill" objectFit="cover" />
      </div>

      <div className="laptop:hidden">
        <Image src={`/${locale}/images/404-desktop.jpg`} alt="Sheet Society" width="425" height="240" />
      </div>

      <h1 className=" w-full absolute top-1/2 left-1/2 heading-2 text-white errorTitle text-center">
        We can’t find that page
      </h1>
    </div>

    <div className="wrapper">
      <h3 className="heading-3 laptop:heading-5 pb-12 text-center">
        Sorry, we obviously didn't get our eight hours.
      </h3>
      <h5 className="body pb-24 text-center">Try one of these to get you back on track:</h5>
      <div className="w-full laptop:wrapper display flex justify-center pb-32">
        <TagFilters items={LINKS} overflow="wrap" alignment="left" />
      </div>

      <div className="block pb-40 laptop:flex justify-center gap-16 laptop:pb-80">
        <Link href={`/${locale || ''}`} passHref legacyBehavior>
          <Button variant="primary" className="mb-16 laptop:mb-0">
            BACK TO HOMEPAGE
          </Button>
        </Link>
        {/* <Button variant="buttonLink">SEARCH FOR SOMETHING</Button> */}
      </div>
    </div>

    <style jsx>
      {`
        .banner {
          height: 55vh;
        }
        .errorTitle {
          transform: translate(-50%, -50%);
        }

        @media (max-width: 425px) {
          .banner {
            height: auto;
          }
        }
      `}
    </style>
  </>;
}

export default Error404
