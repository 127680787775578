import { Crumb } from '@lib/types/Breadcrumbs'
import cn from 'classnames'
import { BreadcrumbJsonLd } from 'next-seo'
import Link from 'next/link'
import { Fragment } from 'react'
interface Props {
  crumbs: Crumb[]
  generateBreadCrumbJson?: boolean
  mobileTextsColour?: string
}

const Breadcrumbs = ({ crumbs, generateBreadCrumbJson = true, mobileTextsColour }: Props) => {
  if (!crumbs || !crumbs.length) return <></>

  const itemListElements = crumbs?.map((c, index) => ({
    position: index + 1,
    name: c.label,
    item: c?.link,
  }))

  const linksColour = (mobileTextsColour?: string) => {
    if (mobileTextsColour === 'dark') {
      return '#000000'
    } else if (mobileTextsColour === 'light') {
      return '#fff'
    } else {
      return '#000000'
    }
  }
  const currentLinkColour = (mobileTextsColour?: string) => {
    if (mobileTextsColour === 'dark') {
      return '#6A6A6A'
    } else if (mobileTextsColour === 'light') {
      return '#fff'
    } else {
      return '#6A6A6A'
    }
  }
  const linkDividerColour = (mobileTextsColour?: string) => {
    if (mobileTextsColour === 'dark') {
      return '#6A6A6A'
    } else if (mobileTextsColour === 'light') {
      return '#fff'
    } else {
      return '#6A6A6A'
    }
  }

  return (
    <>
      {generateBreadCrumbJson && <BreadcrumbJsonLd itemListElements={itemListElements} />}
      <div className={cn('links flex gap-6 w-full overflow-x-scroll laptop:overflow-x-hidden ')}>
        {crumbs.map((crumb, index) => {
          return (
            <Fragment key={crumb?.label}>
              {index === crumbs.length - 1 ? (
                <>
                  {crumb?.label && (
                    <span className="text-charcoal body-small capitalize whitespace-nowrap currentLink">
                      {crumb?.label}
                    </span>
                  )}
                </>
              ) : (
                <Link key={crumb?.label} href={crumb?.link || '/'}>
                  <>
                    <span className="body-link-small capitalize whitespace-nowrap links">{crumb?.label}</span>
                    <span className="text-doveGrey ml-6 linkDivider">|</span>
                  </>
                </Link>
              )}
            </Fragment>
          )
        })}

        <style jsx>
          {`
            @media (max-width: 425px) {
              .links {
                color: ${linksColour(mobileTextsColour)} !important;
              }
              .currentLink {
                color: ${currentLinkColour(mobileTextsColour)} !important;
              }
              .linkDivider {
                color: ${linkDividerColour(mobileTextsColour)} !important;
              }
            }
          `}
        </style>
      </div>
    </>
  )
}

export default Breadcrumbs
